import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import primax40 from '../assets/images/Primax40.png';
import cableseal from '../assets/images/cableseal.png';
import DuplaAncora from '../assets/images/DuplaAncora.png';
import TriplaTrava from '../assets/images/TriplaTrava.png';
import Transition from './Transition';

const ProductDetail = () => {
    const products = [
        {
            name: "Primax 40cm",
            image: primax40, 
            heading: "Produto com pronta entrega permanente.",
            colors: true,
            mainDescription: "UTILIDADES: especial para big bags, muito versátil para caminhões (baú, lonado caminhão tanque e outros), balanças, almoxarifados, portões,registros, válvulas, caixas plásticas, caixotes, containers, instrumentos em geral, etc.Com logotipo e numeração sequencial de até 11 dígitos, código de barras e QR.Disponível em todas as cores.",
            firstParagraph: "O melhor e mais econômico lacre para as indústrias de açúcar e fertilizantes.",
            secondParagraph: "Único com comprimento de 40 CM e tripla trava de segurança interna.UTILIDADES: especial para big bags, muito versátil para caminhões(baú, lonado caminhão tanque e outros), balanças, almoxarifados, portões, registros, válvulas, caixas plásticas, caixotes, containers, instrumentos em geral, etc.Com logotipo e numeração sequencial de até 11 dígitos, código de barras e QR.Disponível em todas as cores."
        },
        {
            name: "Primax Cable Seal",
            image: cableseal, 
            heading: "Primax manivela metálico:Com corpo plástico em PC, polímero de alto impacto e mecanismo interno metálico de alta segurança, blindado e independente.",
            colors: true,
            mainDescription: "Cabo de aço incorporado no lacre, com 1,7 mm de diâmetro e 25 CM. Podendo atender qualquer outro comprimento de acordo com a sua necessidade.Recomendações de uso :Container, Carreta baú, Carreta frigorífica, Carreta tanque, Caminhões, Vagões, Hidrômetros, entre outros.Disponível em todas as cores, com o seu logotipo e numeração até 11 dígitos, versões com código de barras ou QR",
            firstParagraph: "Cabo de aço incorporado no lacre, com 1,7 mm de diâmetro e 25 CM.Podendo atender qualquer outro comprimento de acordo com a sua necessidade.",
            secondParagraph: "Recomendações de uso :Container, Carreta baú, Carreta frigorífica, Carreta tanque, Caminhões, Vagões, Hidrômetros,entre outros.Disponível em todas as cores, com o seu logotipo e numeração até 11 dígitos, versões com código de barras ou QR."
        },
        {
            name: "Primax Dupla Ancora",
            image: DuplaAncora, 
            heading: false,
            colors: false,
            mainDescription: "Lacre do tipo cadeado dupla âncora, com arame, construído em policarbonato de engenharia, suporta temperaturas acima de 225º e força de 50 KGF.composto por uma cápsula fêmea e um elemento macho, com dupla âncora de segurança, muito robusta e eficiente.Personalizado: logotipo, sequencia numérica até 11 dígitos, opção com código de barras e QR.Utilidades: caminhões tanque, malotes, transportadoras em geral, medidores de energia, hidrômetros, válvulas e registros, caixas e contentores, containers, tambores, Disponível em 10 cores translúcidas.",
            firstParagraph: false,
            secondParagraph: false,
        },
        {
            name: "Primax Tripla Trava",
            image: TriplaTrava, 
            heading: "Lacre com duplo fechamento interno sob pressão dentro da cápsula, ficando completamente isolado e tornando qualquer tentativa de violação ineficaz.",
            colors: false,
            mainDescription: "Os lacres de engenharia de ponta proporcionam um mecanismo de segurança impenetrável, com um sistema interno de tripla trava que impossibilita qualquer violação do selo, seja por manipulação direta ou aplicação de força. O projeto meticuloso de nossa trava triplicada assegura que o selo permaneça intacto, protegendo assim a integridade de seu conteúdo e garantindo a tranquilidade que você merece.",
            firstParagraph: "Disponíveis com comprimentos de 16, 23, 28 e 35 CM.",
            secondParagraph: "Comprimento total: 46,5 CM Força de Ruptura: >27 KGF. UTILIDADE:Big bags, Fechamento de Sacas, Transportes de cargas em geral, fechamento de lonas, Mangueira de hidrante, e outras necessidades HEAVY DUTY.GRAVAÇÕES: Logotipo, numeração sequencial ou códigos de barras.",
        }
        // ,
        // // CADEADO
        // {
        //     name: "Primax, cadeado com arame, dupla âncora",
        //     image: CADEADO, 
        //     heading: "Construido com estrutura externa em plástico de alta resistência e mecanismo interno 100% metálico, com  cápsula blindada, impossibilitando o acesso.",
        //     colors: false,
        //     mainDescription: "Sistema de travamento PRIMLOCK, dispensa o uso de fecho ou ferramentas para lacrar a peça.",
        //     firstParagraph: "Cabo ajustável na aplicação, disponível em todos os comprimentos, o que o torna mais preciso e seguro.",
        //     secondParagraph: false
        // }
    ]
    
    const colors = (
        <div className="colors-container mt-5 d-flex justify-content-center align-items-center gap-3">
            <p className='py-0 my-0 uppercase'>Cores disponíveis:</p>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
    
    const {productUrl} = useParams()
    const [product,setProduct] = useState({});
    
    useEffect(()=>{
        switch(productUrl){
            case "primax-mb-40":
                setProduct(products[0])
                break;
            case "cable-seal":
                setProduct(products[1])
                break;
            case "dupla-ancora":
                setProduct(products[2])
                break;
            case "tripla-trava":
                setProduct(products[3])
                break;
            // case "cadeado":
            //     setProduct(products[4])
            //     break;
            default:
                break;
        }
        window.scroll(0, 0);
    },[productUrl])
    

    return (
        <main className='mh-100 container-xxl'>
            {
                <div className="d-flex justify-content-center my-5">
                    <div className='product-detail-container col-12 col-md-9 p-2 p-sm-5 box-shadow rounded-4 d-flex align-items-center gap-3 flex-column navbar-margin'>
                        <h1 className='poppins fs-1 text-center position-relative'>{product.name}</h1>
                        <img className='w-100 mt-5' src={product.image} alt="" />
                        <p className='fs-5 border p-2 p-sm-5 mt-5'>
                            {product.mainDescription}
                            {product.colors ? colors : null}
                        </p>
                        <h3 className='fs-5 uppercase heading  px-2 px-sm-4'>{product.heading}</h3>
                        <div className="p-2 p-sm-4">
                            <p className='fs-5'>{product.firstParagraph}</p>
                            {product.secondParagraph ? <p className='fs-5'>{product.secondParagraph}</p> : null}
                        </div>
                    </div>
                </div>
            }
        </main>
    )
}

export default Transition(ProductDetail)