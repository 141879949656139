import React from 'react'
import LOGO from '../assets/images/logo.png'
import { Link } from 'react-router-dom'

const Navbar = () => {
    return (
        <nav className='w-100 navbar'>
            <div className="container-xxl h-100">
                <div className='navbar-content d-flex h-100 w-100 justify-content-between align-items-center'>
                    <Link className='h-75' to="/"><img className='h-100 logo' src={LOGO} alt="" /></Link>
                    <input type="checkbox" id="burger" />
                    <label className="burger" htmlFor="burger">
                        <span></span>
                        <span></span>
                        <span></span>
                    </label>
                    <ul className='items h-100'>
                        <li className='item h-100 d-flex justify-content-center align-items-center'>
                            <Link to="/" id='home-button'>INICIO</Link>
                        </li>
                        <li className='item h-100 d-flex justify-content-center align-items-center'>
                            PRODUTOS
                            <div className="submenu">
                                <ul className='px-0 submenu-content d-flex flex-column gap-1'>
                                    <li className='py-3'><Link to="/produto/primax-mb-40">PRIMAX 40 CM</Link></li>
                                    <li className='py-3'><Link to="/produto/cable-seal">Cable Seal</Link></li>
                                    <li className='py-3'><Link to="/produto/dupla-ancora">Dupla Ãncora</Link></li>
                                    <li className='py-3'><Link to="/produto/tripla-trava">Tripla trava</Link></li>
                                </ul>
                            </div>
                        </li>
                        <li className='item h-100 d-flex justify-content-center align-items-center'>
                            CONTATO
                            <div className="submenu">
                                <ul className='px-0 submenu-content d-flex flex-column gap-1'>
                                    <li className='py-3 d-flex align-items-center justify-content-center'>
                                        <Link to="/produto/a">
                                            <svg className='me-1' width="26" height="26" viewBox="0 0 24 24" strokeWidth="1" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                            </svg>
                                            +5515991118173
                                        </Link>
                                    </li>
                                    <li className='py-3 px-2'>
                                        <Link to="/produto/a">
                                            <svg className='me-1' width="26" height="26" viewBox="0 0 24 24" strokeWidth="1" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                                                <path d="M3 7l9 6l9 -6" />
                                            </svg>
                                            comercial
                                            @primmax.com.br
                                        </Link>
                                    </li>
                                    <li className='py-3 px-2'>
                                        <Link to="/produto/a">
                                            <svg className='me-1' width="26" height="26" viewBox="0 0 24 24" strokeWidth="1" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                                <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                                            </svg>
                                            Av. Paulo Antunes Moreira 1815, Distrito Industrial, Iperó SP.
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar